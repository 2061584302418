import {
  EXPORT_DOCUMENT_FAIL,
  EXPORT_DOCUMENT_REQUEST,
  EXPORT_DOCUMENT_SUCCESS,
  GET_STRUCTURED_DOCUMENT_FAIL,
  GET_STRUCTURED_DOCUMENT_REQUEST,
  GET_STRUCTURED_DOCUMENT_SUCCESS,
} from "../constants/apiConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  documents: [],
};

export const getStructuredDocumentsReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_STRUCTURED_DOCUMENT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_STRUCTURED_DOCUMENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      documents: payload,
      errors: [],
    };
  } else if (type === GET_STRUCTURED_DOCUMENT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const exportDocumentReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === EXPORT_DOCUMENT_REQUEST) {
    return { ...state, loading: true };
  } else if (type === EXPORT_DOCUMENT_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === EXPORT_DOCUMENT_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
