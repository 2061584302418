import { createStore, applyMiddleware, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";

//Reducers
import { userSignupReducer, userLoginReducer } from "./reducers/authReducers";

import {
  createApiTokenReducer,
  getApiTokensReducer,
  deleteApiTokenReducer,
} from "./reducers/tokenReducers";

import {
  getUserDataReducer,
  editProfileReducer,
  uploadImageReducer,
} from "./reducers/userReducers";

import {
  getStructuredDocumentsReducer,
  exportDocumentReducer,
} from "./reducers/apiReducers";

import { getUserStatisticsReducers } from "./reducers/dashboardReducers";

import {
  inviteUserReducer,
  getInviteReducer,
  getMyInviteReducer,
  acceptInviteReducer,
  removeInviteReducer,
  switchUserReducer,
} from "./reducers/userManagementReducers";

const rootReducers = combineReducers({
  userSignupReducer,
  userLoginReducer,

  createApiTokenReducer,
  getApiTokensReducer,
  deleteApiTokenReducer,

  getUserDataReducer,
  editProfileReducer,
  uploadImageReducer,

  getStructuredDocumentsReducer,
  exportDocumentReducer,

  getUserStatisticsReducers,

  inviteUserReducer,
  getInviteReducer,
  getMyInviteReducer,
  acceptInviteReducer,
  removeInviteReducer,
  switchUserReducer,
});

const middlewares = [thunkMiddleware];
const Store = createStore(
  rootReducers,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export default Store;
