//Send Invite
export const SEND_INVITE_REQUEST = "SEND_INVITE_REQUEST";
export const SEND_INVITE_SUCCESS = "SEND_INVITE_SUCCESS";
export const SEND_INVITE_FAIL = "SEND_INVITE_FAIL";

//Get Invite
export const GET_INVITE_REQUEST = "GET_INVITE_REQUEST";
export const GET_INVITE_SUCCESS = "GET_INVITE_SUCCESS";
export const GET_INVITE_FAIL = "GET_INVITE_FAIL";

//Get My Invite
export const GET_MY_INVITE_REQUEST = "GET_MY_INVITE_REQUEST";
export const GET_MY_INVITE_SUCCESS = "GET_MY_INVITE_SUCCESS";
export const GET_MY_INVITE_FAIL = "GET_MY_INVITE_FAIL";

//Accept Invite
export const ACCEPT_INVITE_REQUEST = "ACCEPT_INVITE_REQUEST";
export const ACCEPT_INVITE_SUCCESS = "ACCEPT_INVITE_SUCCESS";
export const ACCEPT_INVITE_FAIL = "ACCEPT_INVITE_FAIL";

//Remove Invite
export const REMOVE_INVITE_REQUEST = "REMOVE_INVITE_REQUEST";
export const REMOVE_INVITE_SUCCESS = "REMOVE_INVITE_SUCCESS";
export const REMOVE_INVITE_FAIL = "REMOVE_INVITE_FAIL";

//Switch User
export const SWITCH_USER_REQUEST = "SWITCH_USER_REQUEST";
export const SWITCH_USER_SUCCESS = "SWITCH_USER_SUCCESS";
export const SWITCH_USER_FAIL = "SWITCH_USER_FAIL";
