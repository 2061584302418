//Create Token
export const CREATE_API_TOKEN_REQUEST = "CREATE_API_TOKEN_REQUEST";
export const CREATE_API_TOKEN_SUCCESS = "CREATE_API_TOKEN_SUCCESS";
export const CREATE_API_TOKEN_FAIL = "CREATE_API_TOKEN_FAIL";

//Get USER Token
export const GET_API_TOKENS_REQUEST = "GET_API_TOKENS_REQUEST";
export const GET_API_TOKENS_SUCCESS = "GET_API_TOKENS_SUCCESS";
export const GET_API_TOKENS_FAIL = "GET_API_TOKENS_FAIL";

//Delet API Token
export const DELETE_API_TOKEN_REQUEST = "DELETE_API_TOKEN_REQUEST";
export const DELETE_API_TOKEN_SUCCESS = "DELETE_API_TOKEN_SUCCESS";
export const DELETE_API_TOKEN_FAIL = "DELETE_API_TOKEN_FAIL";
