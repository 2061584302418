import React from "react";
import {
  HomeOutlined,
  PlayCircleOutlined,
  ReadOutlined,
  KeyOutlined,
  QuestionCircleOutlined,
  SettingOutlined,
} from "@ant-design/icons";
import { MdUploadFile } from "react-icons/md";
import { IoDocumentsOutline } from "react-icons/io5";

//Components
import { webRoutes } from "../../routes/web";

export const sidebar = (role) => {
  let menuItems = [
    {
      path: webRoutes.dashboard,
      key: webRoutes.dashboard,
      name: "Dashboard",
      icon: <HomeOutlined />,
    },
    {
      path: webRoutes.playground,
      key: webRoutes.playground,
      name: "Playground",
      icon: <PlayCircleOutlined />,
    },
    {
      path: webRoutes.companyDatabase,
      key: webRoutes.companyDatabase,
      name: "Company Database",
      icon: <IoDocumentsOutline />,
    },
    {
      path: webRoutes.documentation,
      key: webRoutes.documentation,
      name: "Documentation",
      icon: <ReadOutlined />,
    },
    {
      path: webRoutes.companyRequest,
      key: webRoutes.companyRequest,
      name: "Company Request",
      icon: <MdUploadFile />,
    },
    {
      path: webRoutes.apiTokens,
      key: webRoutes.apiTokens,
      name: "API Tokens",
      icon: <KeyOutlined />,
    },
    // {
    //   path: webRoutes.userManagement,
    //   key: webRoutes.userManagement,
    //   name: "User Management",
    //   icon: <UserOutlined />,
    // },
    // {
    //   path: webRoutes.billing,
    //   key: webRoutes.billing,
    //   name: "Billing",
    //   icon: <DollarCircleOutlined />,
    // },
    {
      path: webRoutes.support,
      key: webRoutes.support,
      name: "Support",
      icon: <QuestionCircleOutlined />,
    },

    {
      path: webRoutes.setting,
      key: webRoutes.setting,
      name: "Settings",
      icon: <SettingOutlined />,
    },
  ];

  if (role !== "owner") {
    menuItems = menuItems.filter(
      (item) => item.name !== "User Management" && item.name !== "Billing"
    );
  }

  return menuItems;
};
