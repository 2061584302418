import React, { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import BasePageContainer from "../layout/PageContainer";
import { webRoutes } from "../../routes/web";
import { BASE_URL } from "../../redux/constants/constants";

const Request = () => {
  const [formData, setFormData] = useState({
    name: "",
    useremail: "",
    area: "Add Company Request ",
    message: "",
    country: "",
    lei: "",
    companyname: "",
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const supportAreas = [
    { value: "Add Company Request", label: "Add Company Request", icon: "🏢" },
  ];

  const validateForm = () => {
    const newErrors = {};

    // Validate name
    if (!formData.name.trim()) {
      newErrors.name = "Name is required";
    }

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!formData.useremail.trim()) {
      newErrors.useremail = "Email is required";
    } else if (!emailRegex.test(formData.useremail)) {
      newErrors.useremail = "Please enter a valid email address";
    }

    // Validate company name
    if (!formData.companyname.trim()) {
      newErrors.companyname = "Company name is required";
    }

    // Validate country
    if (!formData.country.trim()) {
      newErrors.country = "Country is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));

    // Clear the specific error when user starts typing
    if (errors[name]) {
      setErrors((prev) => ({
        ...prev,
        [name]: undefined,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate form before submission
    if (!validateForm()) {
      return;
    }

    setIsSubmitting(true);
    const loadingToast = toast.loading("Sending your message...");

    try {
      const response = await fetch(`${BASE_URL}/api/document/send-email`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Server error: ${response.status}`);
      }

      const data = await response.json();

      // Handle errors from server
      if (!response.ok) {
        const errorMessage =
          data.message || "Failed to send message. Please try again later.";
        toast.update(loadingToast, {
          render: errorMessage,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        return;
      }

      // Success handling
      if (data.success) {
        toast.update(loadingToast, {
          render: "Message sent successfully!",
          type: "success",
          isLoading: false,
          autoClose: 3000,
        });

        // Reset form fields
        setFormData({
          name: "",
          useremail: "",
          area: "Add Company Request ",
          message: "",
          country: "",
          lei: "",
          companyname: "",
        });
        setErrors({});
      }
    } catch (error) {
      toast.update(loadingToast, {
        render: "Network error. Please check your connection.",
        type: "error",
        isLoading: false,
        autoClose: 3000,
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const breadcrumb = {
    items: [
      {
        key: webRoutes.dashboard,
        title: <Link to={webRoutes.dashboard}>Dashboard</Link>,
      },
      {
        key: webRoutes.support,
        title: <Link to={webRoutes.companyRequest}>Company Request</Link>,
      },
    ],
  };

  return (
    <BasePageContainer breadcrumb={breadcrumb} transparent={true}>
      <div className="min-h-screen ">
        <div className="max-w-5xl w-full">
          <div className="max-w-2xl ">
            <ToastContainer
              position="top-right"
              autoClose={3000}
              hideProgressBar={false}
              newestOnTop={true}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />

            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
              <div className="bg-gradient-to-r bg-[#171733] px-5 py-3">
                <h2 className="text-lg font-semibold text-white font-inter-tight">
                  Submit a Company Request
                </h2>
              </div>
              <div className="p-5">
                <p className="text-gray-600 font-inter">
                  If you're looking for data on a company that isn't currently
                  available, we'd be happy to assist. We're continuously
                  expanding our dataset, and we welcome your requests. We're
                  committed to improving our services and look forward to
                  accommodating your request.
                </p>

              </div>

              <form
                onSubmit={handleSubmit}
                className="p-5 space-y-4 font-inter"
              >
                {/* Name Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:ring-1 focus:border-[#171733] transition-colors outline-none ${
                      errors.name 
                        ? "border-red-500 focus:ring-red-500" 
                        : "border-gray-300"
                    }`}
                    placeholder="Enter your full name"
                  />
                  {errors.name && (
                    <p className="text-red-500 text-xs mt-1">{errors.name}</p>
                  )}
                </div>

                {/* Email Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Your Email
                  </label>
                  <input
                    type="text"
                    name="useremail"
                    value={formData.useremail}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:ring-1 focus:border-[#171733] transition-colors outline-none ${
                      errors.useremail 
                        ? "border-red-500 focus:ring-red-500" 
                        : "border-gray-300"
                    }`}
                    placeholder="Provide your email address"
                  />
                  {errors.useremail && (
                    <p className="text-red-500 text-xs mt-1">{errors.useremail}</p>
                  )}
                </div>

                {/* Company Name Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="companyname"
                    value={formData.companyname}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:ring-1 focus:border-[#171733] transition-colors outline-none ${
                      errors.companyname 
                        ? "border-red-500 focus:ring-red-500" 
                        : "border-gray-300"
                    }`}
                    placeholder="Specify the company name you are requesting"
                  />
                  {errors.companyname && (
                    <p className="text-red-500 text-xs mt-1">{errors.companyname}</p>
                  )}
                </div>

                {/* Country Input */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    Country
                  </label>
                  <input
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleInputChange}
                    className={`w-full px-3 py-2 border rounded-md focus:ring-1 focus:border-[#171733] transition-colors outline-none ${
                      errors.country 
                        ? "border-red-500 focus:ring-red-500" 
                        : "border-gray-300"
                    }`}
                    placeholder="Mention the company's origin country"
                  />
                  {errors.country && (
                    <p className="text-red-500 text-xs mt-1">{errors.country}</p>
                  )}
                </div>

                {/* LEI Input (optional) */}
                <div>
                  <label className="block text-sm font-medium text-gray-700">
                    LEI (optional)
                  </label>
                  <input
                    type="text"
                    name="lei"
                    value={formData.lei}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-[#171733] focus:border-[#171733] transition-colors outline-none"
                    placeholder="Input the accurate LEI number of the company"
                  />
                </div>

                {/* Support Area Select */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Support Area
                  </label>
                  <select
                    name="area"
                    value={formData.area}
                    onChange={handleInputChange}
                    disabled
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-[#171733] focus:border-[#171733] transition-colors outline-none bg-gray-100"
                  >
                    {supportAreas.map((area) => (
                      <option key={area.value} value={area.value}>
                        {area.icon} {area.label}
                      </option>
                    ))}
                  </select>
                </div>

                {/* Message Textarea */}
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Message
                  </label>
                  <textarea
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                    rows={5}
                    className="w-full px-3 py-2 border border-gray-300 rounded-md focus:ring-1 focus:ring-[#171733] focus:border-[#171733] transition-colors outline-none"
                    placeholder="Share detailed information about your request"
                  />
                </div>

                {/* Submit Button */}
                <div>
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className="w-full bg-[#171733] hover:bg-[#171733] text-white font-medium py-2 rounded-md transition-colors disabled:opacity-50 disabled:cursor-not-allowed flex items-center justify-center space-x-2"
                  >
                    {isSubmitting ? (
                      <>
                        <svg
                          className="animate-spin h-5 w-5 text-white"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <circle
                            className="opacity-25"
                            cx="12"
                            cy="12"
                            r="10"
                            stroke="currentColor"
                            strokeWidth="4"
                          ></circle>
                          <path
                            className="opacity-75"
                            fill="currentColor"
                            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                          ></path>
                        </svg>
                        <span>Sending...</span>
                      </>
                    ) : (
                      <>
                      
                        <span>Send Message</span>
                      </>
                    )}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </BasePageContainer>
  );
};

export default Request;