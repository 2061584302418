import {
  SAVE_USER_INFO,
  USER_LOGIN_FAIL,
  USER_LOGIN_REQUEST,
  USER_LOGIN_SUCCESS,
  USER_SIGNUP_FAIL,
  USER_SIGNUP_REQUEST,
  USER_SIGNUP_SUCCESS,
} from "../constants/authConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  userId: "",
  email: "",
  role: "",
  username: "",
};

export const userSignupReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === USER_SIGNUP_REQUEST) {
    return { ...state, loading: true };
  } else if (type === USER_SIGNUP_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === USER_SIGNUP_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const userLoginReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === USER_LOGIN_REQUEST) {
    return { ...state, loading: true };
  } else if (type === USER_LOGIN_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      userId: payload,
      errors: [],
    };
  } else if (type === USER_LOGIN_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else if (type === SAVE_USER_INFO) {
    return {
      ...state,
      userId: payload.userId,
      email: payload.email,
      role: payload.role,
      username: payload.username,
    };
  } else {
    return state;
  }
};
