// ctiLogo.js

const ctiLogo = (
    <svg
      version="1.1"
      id="Lager_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      x="0px"
      y="0px"
      viewBox="0 0 1196.2 867.5"
      style={{ enableBackground: 'new 0 0 1196.2 867.5' }}
      xmlSpace="preserve"
      width="50" // Set width as needed
      height="30" // Set height as needed
    >
      <style type="text/css">
        {`.st0{fill:#171733;}`}
      </style>
      <g>
        <rect x="908.2" y="450.4" className="st0" width="279.7" height="417.2"/>
        <circle className="st0" cx="1046.9" cy="230.4" r="149.3"/>
        <polygon className="st0" points="741.6,380.8 741.6,380.8 837.7,380.8 837.7,97.6 741.6,97.6 741.6,0 458.3,0 458.3,97.6 458.3,380.8 458.3,380.8"/>
        <path className="st0" d="M743.5,580.5V542v-29.1v-34.8v-26.7H458.3V484c0,210.6,171.3,381.9,381.9,381.9V580.7l-0.6-0.2H743.5z"/>
        <path className="st0" d="M385.2,380.8l3,0V81.2C202.1,81.2,45.1,208.6,0,380.8H385.2z"/>
        <path className="st0" d="M287,450.4H1.5C0.6,461.2,0,472.1,0,483.1c0,212,172.5,384.5,384.5,384.5V580.5h-97.5L287,450.4z"/>
      </g>
    </svg>
  );
  
  // Exporting the logo
  export default ctiLogo;
  