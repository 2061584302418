import React from "react";

//Packages
import AppRouter from "./routes/browserRouter";
import { Toaster } from "sonner";

function App() {
  return (
    <div className="fade-in">
      <AppRouter />
      <Toaster />
    </div>
  );
}

export default App;
