import {
  CREATE_API_TOKEN_FAIL,
  CREATE_API_TOKEN_REQUEST,
  CREATE_API_TOKEN_SUCCESS,
  DELETE_API_TOKEN_FAIL,
  DELETE_API_TOKEN_REQUEST,
  DELETE_API_TOKEN_SUCCESS,
  GET_API_TOKENS_FAIL,
  GET_API_TOKENS_REQUEST,
  GET_API_TOKENS_SUCCESS,
} from "../constants/tokenConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  tokenData: {},
  apiTokens: [],
};

export const createApiTokenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === CREATE_API_TOKEN_REQUEST) {
    return { ...state, loading: true };
  } else if (type === CREATE_API_TOKEN_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      tokenData: payload,
      errors: [],
    };
  } else if (type === CREATE_API_TOKEN_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const getApiTokensReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_API_TOKENS_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_API_TOKENS_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      apiTokens: payload,
      errors: [],
    };
  } else if (type === GET_API_TOKENS_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};

export const deleteApiTokenReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === DELETE_API_TOKEN_REQUEST) {
    return { ...state, loading: true };
  } else if (type === DELETE_API_TOKEN_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === DELETE_API_TOKEN_FAIL) {
    return { ...state, loading: false, success: false, errors: payload };
  } else {
    return state;
  }
};
