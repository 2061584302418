import { React, useEffect } from "react";
import { Outlet, useNavigate, useLocation } from "react-router-dom";
import { ProLayout } from "@ant-design/pro-components";
import Icon, { LogoutOutlined } from "@ant-design/icons";
import { RiShieldUserFill } from "react-icons/ri";
import { Dropdown } from "antd";
import { supabase } from "../../config/supabase";
import { toast } from "sonner";
import { useDispatch, useSelector } from "react-redux";
import ctiLogo from "./ctilogo";

//Components
import { sidebar } from "./sidebar";
import { webRoutes } from "../../routes/web";

export default function Layout() {
  const dispatch = useDispatch();
  const { username, role } = useSelector((state) => state.userLoginReducer);

  const location = useLocation();
  const navigate = useNavigate();
  const filteredSidebar = sidebar(role);

  const defaultProps = {
    title: `Hello, ${username || 'User'}`, // Added fallback
    logo: (<div className="mr-4" >{ctiLogo}</div>),
    fixedHeader: true,
    fixSiderbar: true,
    layout: "mix",
    route: {
      routes: filteredSidebar,
    },
  };

  // Fixed useEffect
  useEffect(() => {
    // Assuming you have an action creator called setUser
    if (username) {
      dispatch({ type: 'SET_USER', payload: username });
    }
  }, [dispatch]);

  //Functions
  const handleLogout = async () => {
    const { error } = await supabase.auth.signOut();
    if (error) return toast.error(error.message);
    window.location.reload();
  };

  return (
    <div className="h-screen">
      <ProLayout
        {...defaultProps}
        token={{
          sider: {
            colorMenuBackground: "white",
          },
        }}
        location={location}
        onMenuHeaderClick={() => navigate(webRoutes.dashboard)}
        menuItemRender={(item, dom) => {
          const isActive = location.pathname === item.path;
          return (
            <a
            
              onClick={(e) => {
                e.preventDefault();
                item.path && navigate(item.path);
              }}
              href={item.path}
              style={{
                fontFamily: '"Inter Tight", sans-serif',
                color: isActive ? '#FF6B00' : 'inherit',
              }}
            >
              {dom}
            </a>
          );
        }}
        avatarProps={{
          icon: <Icon component={RiShieldUserFill} />,
          className: "bg-primary bg-opacity-20 text-primary text-opacity-90 font-inter-tight",
          size: "small",
          shape: "square",
          title: (<>
            {username}
          </>),
          render: (_, dom) => {
            return (
              <>
                <Dropdown
                  menu={{
                    items: [
                      {
                        key: "logout",
                        icon: <LogoutOutlined />,
                        label: "Logout",
                        onClick: () => {
                          handleLogout();
                        },
                      },
                    ],
                  }}
                >
                  {dom}
                </Dropdown>
              </>
            );
          },
        }}
      >
        <Outlet />
      </ProLayout>
    </div>
  );
}