//Get Structured Documents
export const GET_STRUCTURED_DOCUMENT_REQUEST =
  "GET_STRUCTURED_DOCUMENT_REQUEST";
export const GET_STRUCTURED_DOCUMENT_SUCCESS =
  "GET_STRUCTURED_DOCUMENT_SUCCESS";
export const GET_STRUCTURED_DOCUMENT_FAIL = "GET_STRUCTURED_DOCUMENT_FAIL";

//Export Document
export const EXPORT_DOCUMENT_REQUEST = "EXPORT_DOCUMENT_REQUEST";
export const EXPORT_DOCUMENT_SUCCESS = "EXPORT_DOCUMENT_SUCCESS";
export const EXPORT_DOCUMENT_FAIL = "EXPORT_DOCUMENT_FAIL";