import {
  ACCEPT_INVITE_FAIL,
  ACCEPT_INVITE_REQUEST,
  ACCEPT_INVITE_SUCCESS,
  GET_INVITE_FAIL,
  GET_INVITE_REQUEST,
  GET_INVITE_SUCCESS,
  GET_MY_INVITE_FAIL,
  GET_MY_INVITE_REQUEST,
  GET_MY_INVITE_SUCCESS,
  REMOVE_INVITE_FAIL,
  REMOVE_INVITE_REQUEST,
  REMOVE_INVITE_SUCCESS,
  SEND_INVITE_FAIL,
  SEND_INVITE_REQUEST,
  SEND_INVITE_SUCCESS,
  SWITCH_USER_FAIL,
  SWITCH_USER_REQUEST,
  SWITCH_USER_SUCCESS,
} from "../constants/userManagementConstants";

const initialState = {
  loading: false,
  success: false,
  errors: [],
  invites: [],
  myInvites: [],
  invite: {},
};

export const inviteUserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === SEND_INVITE_REQUEST) {
    return { ...state, loading: true };
  } else if (type === SEND_INVITE_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      invite: payload,
      errors: [],
    };
  } else if (type === SEND_INVITE_FAIL) {
    return {
      ...state,
      loading: false,
      success: false,
      errors: payload,
    };
  } else {
    return state;
  }
};

export const getInviteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_INVITE_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_INVITE_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
      invites: payload,
    };
  } else if (type === GET_INVITE_FAIL) {
    return {
      ...state,
      loading: false,
      success: false,
      errors: payload,
    };
  } else {
    return state;
  }
};

export const getMyInviteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === GET_MY_INVITE_REQUEST) {
    return { ...state, loading: true };
  } else if (type === GET_MY_INVITE_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
      myInvites: payload,
    };
  } else if (type === GET_MY_INVITE_FAIL) {
    return {
      ...state,
      loading: false,
      success: false,
      errors: payload,
    };
  } else {
    return state;
  }
};

export const acceptInviteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === ACCEPT_INVITE_REQUEST) {
    return { ...state, loading: true };
  } else if (type === ACCEPT_INVITE_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === ACCEPT_INVITE_FAIL) {
    return {
      ...state,
      loading: false,
      success: false,
      errors: payload,
    };
  } else {
    return state;
  }
};

export const removeInviteReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === REMOVE_INVITE_REQUEST) {
    return { ...state, loading: true };
  } else if (type === REMOVE_INVITE_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === REMOVE_INVITE_FAIL) {
    return {
      ...state,
      loading: false,
      success: false,
      errors: payload,
    };
  } else {
    return state;
  }
};

export const switchUserReducer = (state = initialState, action) => {
  const { type, payload } = action;
  if (type === SWITCH_USER_REQUEST) {
    return { ...state, loading: true };
  } else if (type === SWITCH_USER_SUCCESS) {
    return {
      ...state,
      loading: false,
      success: true,
      errors: [],
    };
  } else if (type === SWITCH_USER_FAIL) {
    return {
      ...state,
      loading: false,
      success: false,
      errors: payload,
    };
  } else {
    return state;
  }
};
