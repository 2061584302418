export const webRoutes = {
  home: "/",
  login: "/login",
  signup: "/signup",
  logout: "/logout",
  dashboard: "/dashboard",
  playground: "/playground",
  companyDatabase: "/company-database",
  documentation: "/documentation",
  apiTokens: "/api-tokens",
  userManagement: "/user-management",
  billing: "/billing",
  support: "/support",
  companyRequest: "/company-request",
  setting: "/setting",
};
