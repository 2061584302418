import React, { useEffect, useState } from "react";
import { RouterProvider, createBrowserRouter, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import loadable from "@loadable/component";
import { supabase } from "../config/supabase";

// Utils
import { webRoutes } from "./web";

// Redux State
import { SAVE_USER_INFO } from "../redux/constants/authConstants";

// Components
import AuthLayout from "../components/auth/AuthLayout";
import Login from "../components/auth/Login";
import Signup from "../components/auth/Signup";
import ErrorPage from "../components/error/ErrorPage";
import Layout from "../components/layout";
import ProgressBar from "../components/loader/ProgressBar";
import Redirect from "../components/layout/Redirect";
import RequireAuth from "./RequireAuth";
import NotFoundPage from "../components/error/NotFoundPage";
import Requests from "../components/company request";

// Load Pages
const Dashboard = loadable(() => import("../components/dashboard"), {
  fallback: <ProgressBar />,
});
const Playground = loadable(() => import("../components/playground"), {
  fallback: <ProgressBar />,
});
const CompanyDatabase = loadable(
  () => import("../components/company_database"),
  {
    fallback: <ProgressBar />,
  }
);
const Documentation = loadable(() => import("../components/documentation"), {
  fallback: <ProgressBar />,
});
const Api = loadable(() => import("../components/api"), {
  fallback: <ProgressBar />,
});
const UserManagement = loadable(() => import("../components/user"), {
  fallback: <ProgressBar />,
});
const Billing = loadable(() => import("../components/billing"), {
  fallback: <ProgressBar />,
});
const Support = loadable(() => import("../components/support"), {
  fallback: <ProgressBar />,
});
const Setting = loadable(() => import("../components/setting"), {
  fallback: <ProgressBar />,
});

export default function AppRouter() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);

  // Get User ID
  useEffect(() => {
    const fetchSession = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase.auth.getSession();

        if (data.session) {
          const { data: user_data } = await supabase
            .from("users")
            .select("*")
            .eq("id", data.session.user.id);

          setUser(data.session.user);
          dispatch({
            type: SAVE_USER_INFO,
            payload: {
              userId: user_data[0].id,
              email: user_data[0].email,
              role: user_data[0].role,
              username: user_data[0].name,
            },
          });
        } else if (error) {
          console.log("Error getting session:", error);
        }
      } catch (error) {
        console.error("Error getting session:", error.message);
      }
      setLoading(false);
    };

    fetchSession();
  }, [dispatch]);

  if (loading) return <ProgressBar />;

  const browserRouter = createBrowserRouter([
    {
      path: webRoutes.home,
      element: <Redirect />,
      errorElement: <ErrorPage />,
    },
    // Auth Routes
    {
      element: <AuthLayout />,
      errorElement: <ErrorPage />,
      children: [
        {
          path: webRoutes.login,
          element: <Login />,
        },
        {
          path: webRoutes.signup,
          element: <Signup />,
        },
      ],
    },
    // Protected Routes Layout
    {
      element: user ? (
        <Layout>
          <Outlet />
        </Layout>
      ) : null,
      errorElement: <ErrorPage />,
      children: [
        {
          path: webRoutes.dashboard,
          element: (
            <RequireAuth>
              <Dashboard />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.playground,
          element: (
            <RequireAuth>
              <Playground />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.companyDatabase,
          element: (
            <RequireAuth>
              <CompanyDatabase />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.apiTokens,
          element: (
            <RequireAuth>
              <Api />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.userManagement,
          element: (
            <RequireAuth>
              <UserManagement />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.billing,
          element: (
            <RequireAuth>
              <Billing />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.support,
          element: (
            <RequireAuth>
              <Support />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.companyRequest,
          element: (
            <RequireAuth>
              <Requests />
            </RequireAuth>
          ),
        },
        {
          path: webRoutes.setting,
          element: (
            <RequireAuth>
              <Setting />
            </RequireAuth>
          ),
        },
        // Documentation route for authenticated users
        {
          path: webRoutes.documentation,
          element: <Documentation />,
        },
      ],
    },
    // Public Documentation Route (for non-authenticated users)
    {
      path: webRoutes.documentation,
      element: !user ? <Documentation /> : null,
      errorElement: <ErrorPage />,
    },
    // 404
    {
      path: "*",
      element: <NotFoundPage />,
      errorElement: <ErrorPage />,
    },
  ]);

  return <RouterProvider router={browserRouter} />;
}
